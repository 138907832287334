
import Navbar from './global/navbar';
import PageHeader from './global/page-header';
import Services from './services-components/services-card';
import Footer from './global/footer';
import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import { SectionMerlos } from './global/SectionMerlos';

const ServicePage = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <Navbar />
        <PageHeader headertitle="Services" Subheader="Services" bgimg={rpdata?.gallery?.[3]} />
        <SectionMerlos/>
        <Services />
        <Footer />
    </div>
}

export default ServicePage

