import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

const ServiceBlock = ({
  servicesName,
  description,
  itemServives,
  imgServices,
}) => {
  const { rpdata } = useContext(GlobalDataContext);
  // let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="about-area bg-gray pd-top-120 pd-bottom-120">
      <div className="container">
        <div className="single-about-inner about-line-right bg-white">
          <div className="row no-gutter">
            <div className="col-lg-4 order-lg-12">
              <div
                className="thumb"
                style={{ backgroundImage: `url("${imgServices}")` }}
              />
            </div>
            <div className="col-lg-8 order-lg-1">
              <div className="details">
                <div className="section-title mb-4">
                  <h2>{rpdata?.dbPrincipal?.name}</h2>
                  <p className="content">{description}</p>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="single-list-wrap">{itemServives}</ul>
                  </div>
                </div>

                <Link className="btn btn-base" to="/contact">
                  FREE ESTIMATE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBlock;
